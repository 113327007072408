'use strict';

import _ from 'lodash';
import moment from 'moment';
import colors from '@olono/shared-ui-assets/colors.json';

const completeColor = colors['cyan-300'];
const recommendColor = colors['yellow-300'];
const dismissColor = colors['light-blue-300'];

export default class ActionGraphController {
    static get $inject() {
        return ['$scope', '$log', 'actionService'];
    }

    constructor($scope, $log, actionService) {
        // small debounce here to forgive init listeners firing
        $scope.getStats = _.debounce(getStats, 200);
        $scope.setRange = setRange;

        const defaultFilterWatcher = $scope.$watch('defaultFilter', function() {
            if ($scope.graphOptions) {
                $scope.interval = _.get($scope.graphOptions, 'range', '3M');
                $scope.setRange($scope.interval);
            } else {
                $scope.setRange('3M');
            }
        });

        const filterDataWatcher = $scope.$watch('filterData', function() {
            $scope.query = _.get($scope.filterData, 'query');
            $scope.defaultFilterExtensions = _.get($scope.filterData, 'defaultFilterExtensions', {});
            $scope.getStats();
        });

        const graphOptionWatcher = $scope.$watch('graphOptions.defaultFilter', function() {
            if ($scope.graphOptions) {
                $scope.defaultFilter = _.get($scope.graphOptions, 'defaultFilter', {});
            }
            $scope.getStats();
        });

        $scope.$on('$destroy', () => {
            filterDataWatcher();
            defaultFilterWatcher();
            graphOptionWatcher();
        });

        function getStats() {
            if ($scope.graphOptions) {
                $scope.filter = _.get($scope.graphOptions, 'filter', {});
                $scope.interval = _.get($scope.graphOptions, 'interval', '3M');
                $scope.actionTitle = _.get($scope.graphOptions, 'title', 'Actions');
            }
            actionService
                .getActionStats({
                    defaultFilter: Object.assign({}, $scope.defaultFilter, $scope.defaultFilterExtensions),
                    range: $scope.range,
                    interval: $scope.interval,
                    filter: $scope.filter,
                    query: $scope.query
                })
                .then(function(stats) {
                    $scope.labels = _.map(stats, ({ label }) => {
                        return moment(label, 'MM-DD-YYYY').format('MM/DD');
                    });
                    $scope.series = ['Completed', 'Active', 'Dismissed'];
                    const completed = _.map(stats, 'complete');
                    const dismissed = _.map(stats, 'dismiss');
                    const recommended = _.map(stats, 'recommended');
                    $scope.data = [completed, recommended, dismissed];
                    $scope.totalRecommended = _.sum(recommended);
                    $scope.totalCompleted = _.sum(completed);
                    $scope.totalDismissed = _.sum(dismissed);

                    if ($scope.totalRecommended) {
                        $scope.completionPercentage = _.round(
                            ($scope.totalCompleted / ($scope.totalRecommended + $scope.totalCompleted)) * 100,
                            2
                        );
                    } else {
                        $scope.completionPercentage = 0;
                    }
                })
                .catch($log.error);
        }

        function setRange(range) {
            $scope.currentRange = range;
            $scope.displayLte = moment().toISOString();

            $scope.range = {
                lte: 'now',
                relative: true
            };
            if (range === '1W') {
                $scope.displayGte = moment()
                    .subtract(1, 'week')
                    .toISOString();
                $scope.range.gte = 'now-1w';
                $scope.interval = 'day';
                $scope.rangeString = 'this week';
            } else if (range === '1M') {
                $scope.displayGte = moment()
                    .subtract(1, 'month')
                    .toISOString();
                $scope.range.gte = 'now-1M';
                $scope.interval = 'week';
                $scope.rangeString = 'in the last month';
            } else if (range === '3M') {
                $scope.displayGte = moment()
                    .subtract(3, 'month')
                    .toISOString();
                $scope.range.gte = 'now-3M';
                $scope.interval = 'week';
                $scope.rangeString = 'in the last 3 months';
            } else if (range === '6M') {
                $scope.displayGte = moment()
                    .subtract(6, 'month')
                    .toISOString();
                $scope.range.gte = 'now-6M';
                $scope.interval = 'month';
                $scope.rangeString = 'in the last 6 months';
            } else if (range === '1Y') {
                $scope.displayGte = moment()
                    .subtract(1, 'year')
                    .toISOString();
                $scope.range.gte = 'now-1y';
                $scope.interval = 'month';
                $scope.rangeString = 'in the last year';
            }

            $scope.getStats();
        }

        $scope.colors = [
            {
                backgroundColor: completeColor,
                borderColor: completeColor,
                hoverBackgroundColor: completeColor,
                hoverBorderColor: completeColor
            },
            {
                backgroundColor: recommendColor,
                borderColor: recommendColor,
                hoverBackgroundColor: recommendColor,
                hoverBorderColor: recommendColor
            },
            {
                backgroundColor: dismissColor,
                borderColor: dismissColor,
                hoverBackgroundColor: dismissColor,
                hoverBorderColor: dismissColor
            }
        ];
        $scope.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: { fontColor: colors['grey-500'], padding: 25 },
                        barThickness: 20,
                        gridLines: { display: false }
                    }
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            fontColor: colors['grey-500'],
                            beginAtZero: true,
                            steps: 4,
                            padding: 25
                        },
                        gridLines: { drawBorder: false },
                        barThickness: 20
                    }
                ]
            }
        };
    }
}
