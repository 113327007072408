'use strict';

import colors from '@olono/shared-ui-assets/colors.json';

import '../sass/main.scss';

// This loads Angular, and defines what `serviceApp` is...
import appModule from './app.module';

// ... which are referenced in all these
import './vendor';
import './services';
import './controllers';
import './filters';
import './directives';
import './modules';
import '../components';
import { datadogRum } from '@datadog/browser-rum';

const revBot = 'https://revbot.insightsquared.com/?embeddedIn=i360';
const productName = 'Intelligence';
const productPredict = 'Action Automation';
const productPrimaryHostname = 'olono.ai';
const productSandboxHostname = 'sandbox.olono.ai';
const productSecondaryHostname = 'olono.io';
const productDevelopmentHostname = 'olono.test';
const productSupportHostName = 'support.mediafly.com';
const productSupportHelp = 'support.mediafly.com/hc/en-us/categories/13259714598939-Intelligence360';
const productTrainingHostname = 'assets.mediafly.com/wl/78baa1';
const productWebpage = 'www.olono.ai';
const globalArrayLimit = 6;
const theme = 'jellybean-set';
const unsupportMobileAuth = ['google'];
const is2DisableTrackingPattern = /^IS2_DISABLE_TRACKING!/;

// this is to make sure deep links will still work and go to the correct board
const oldSettingPanelMapping = {
    actions: 'action-definition',
    cards: 'card-definition',
    boards: 'board-definition',
    'content-types': 'content-type-definition',
    groups: 'group-definition',
    ml: 'machine-learning-definition',
    mls: 'machine-learning-definition',
    'forecast-type': 'forecast-type-definition',
    'forecast-types': 'forecast-type-definition',
    goal: 'goal-definition',
    goals: 'goal-definition',
    hierarchy: 'hierarchy-definition',
    hierarchies: 'hierarchy-definition',
    activity: 'activity-definition',
    activities: 'activity-definition'
};

/**
 * @ngdoc overview
 * @name serviceApp
 * @description
 * # serviceApp
 *
 * Main module of the application.
 */
appModule
    .run([
        '_',
        '$rootScope',
        '$state',
        '$stateParams',
        '$log',
        '$location',
        '$window',
        '$http',
        'deviceDetector',
        'screenSize',
        function(
            _,
            $rootScope,
            $state,
            $stateParams,
            $log,

            $location,
            $window,
            $http,
            deviceDetector,
            screenSize
        ) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;

            //Global Product Variables
            $rootScope.revBot = revBot;
            $rootScope.productName = productName;
            $rootScope.productPredict = productPredict;
            $rootScope.productPrimaryHostname = productPrimaryHostname;
            $rootScope.productSandboxHostname = productSandboxHostname;
            $rootScope.productSecondaryHostname = productSecondaryHostname;
            $rootScope.productDevelopmentHostname = productDevelopmentHostname;
            $rootScope.productSupportHostName = productSupportHostName;
            $rootScope.productSupportHelp = productSupportHelp;
            $rootScope.productTrainingHostname = productTrainingHostname;
            $rootScope.productWebpage = productWebpage;
            $rootScope.globalArrayLimit = globalArrayLimit;
            $rootScope.theme = theme;
            $rootScope.unsupportMobileAuth = unsupportMobileAuth;

            // If loaded into a mediafly.com / mediafly.test domain, load the mcode
            // from that path into $rootScope for later usage.
            //
            // NB: Unlike window.location.hostname, this doesn't include the port.
            const hostname = $location.host();
            $rootScope.isMediaflyCom =
                hostname.match(/\.mediafly\.com/) ||
                hostname.match(/\.imediafly\.com/) ||
                hostname.match(/\.mediafly\.test/);
            if ($rootScope.isMediaflyCom) {
                // When on mediafly.com, we need to extract the mcode / company code from the URL.
                // This is included as a HTTP header on every API request.
                const mcode = $window.location.pathname.substring(1);
                console.debug(`mediafly.com domain detected, using mcode: ${mcode}`);
                $rootScope.mcode = mcode;

                // Include an `mcode` HTTP header in every outbound XHR request.
                // We could alternatively add this in an interceptor.
                $http.defaults.headers.common['mcode'] = mcode;
            }

            const userAgent = navigator.userAgent;
            const androidWebView = _.includes(userAgent, 'wv');
            const iosWebView =
                deviceDetector.os === 'ios' &&
                _.includes(userAgent, 'Mobile/') &&
                !_.includes(userAgent, 'Safari/');
            $rootScope.isMobile = androidWebView || iosWebView;

            //Setup global media sizes for responsive screens. These are compatible with bootstrap up to 'lg'.
            //Added an additional 'xl', 'xxl' for internal use cases.
            screenSize.rules = {
                xs: '(max-width: 764px)',
                sm: '(min-width: 765px) and (max-width: 991px)',
                md: '(min-width: 992px) and (max-width: 1199px)',
                lg: '(min-width: 1200px) and (max-width: 1499px)',
                xl: '(min-width: 1500px) and (max-width: 1999px)',
                xxl: '(min-width: 2000px)'
            };

            // Global flag indicating that display options should be loaded from the API,
            // even if they are cached. This will force display options to reload on
            // a full page load.
            $rootScope.forceDisplayOptionsLoad = true;

            // START UI-Router debugging
            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                //All state change starts that notify should always reload
                _.set(toParams, 'overwriteReload', false);

                //If coming from an external route make sure it continues on the external routing.
                if (
                    fromState &&
                    toState &&
                    fromState.isExternal &&
                    toState.name !== 'external' &&
                    !_.includes(toState.name, 'core')
                ) {
                    event.preventDefault();
                    $state.go('external', toParams);
                }

                $log.debug(
                    '$stateChangeStart to ' +
                        toState.name +
                        '- fired when the transition begins. toState,toParams : \n',
                    toState,
                    toParams
                );

                $rootScope.routeIsChanging = true;
                // Clear out any filter we got from local storage so it's not
                // applied to API calls unexpectedly.
                $rootScope.currentFilter = null;

                $rootScope.toState = toState;
                $rootScope.toParams = toParams;
                if (toState.isExternal || toState.name == 'mobile.home') {
                    $rootScope.isExternal = true;
                }
            });

            $rootScope.$on('$stateChangeError', function(
                event,
                toState,
                toParams,
                fromState,
                fromParams,
                error
            ) {
                $log.error('$stateChangeError - fired when an error occurs during transition.', arguments);
                $rootScope.routeIsChanging = false;
            });

            $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                if (toState.isExternal || toState.name == 'mobile.home') {
                    $rootScope.isExternal = true;
                }

                $rootScope.currentState = toState;
                $rootScope.routeIsChanging = false;
                $rootScope.containerClass = toState.containerClass;
                let pageTitle = _.get(toState, 'data.pageTitle');
                const boardDef = _.split(_.get(toParams, 'board'), '$');
                // attempt to build page title from board
                if (!pageTitle) {
                    if (_.size(boardDef) === 2) {
                        pageTitle = _.startCase(_.last(boardDef));
                    } else {
                        pageTitle = _.startCase(_.get(toParams, 'title'));
                    }
                }
                $rootScope.pageTitle = pageTitle;
                $log.debug(
                    `$stateChangeSuccess to ${toState.name} - fired once the state transition is complete.`
                );
            });

            $rootScope.$on('$viewContentLoaded', function(event) {
                $log.debug('$viewContentLoaded - fired after dom rendered', event);
            });

            $rootScope.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams) {
                $log.debug(
                    `$stateNotFound ${unfoundState.name} - fired when a state cannot be found by its name.`
                );
                $log.debug(unfoundState, fromState, fromParams);
            });
            // END UI-Router debugging

            // Initialize a list of app-wide socket listeners.
            // TODO -- move this into a separate service that handles one-time subscriptions to app-wide events.
            $rootScope.globalSocketListeners = {};

            $rootScope.$on('logout', function(event, data) {
                $rootScope.gainsightInitialized = false;
                datadogRum.clearUser();

                if ($rootScope.socket) {
                    $rootScope.socket.removeAllListeners();
                    $rootScope.socket.disconnect();
                    $rootScope.socket = undefined;
                }
            });

            $rootScope.$on('identifyUser', function(event, user, team) {
                // FullStory
                let userDetails = {
                    displayName: _.get(user, 'profile.name'),
                    email: _.get(user, 'username'),
                    teamId: _.get(team, 'id'),
                    teamName: _.get(team, 'shortcode'),
                    app: 'platform'
                };

                FS.identify(_.get(user, 'id'), userDetails);

                // Datadog
                datadogRum.setUser({
                    id: _.get(user, 'id'),
                    name: _.get(user, 'profile.name'),
                    email: _.get(user, 'username'),
                    team: _.get(team, 'shortcode')
                });
            });
        }
    ])
    // Given a board and some state params, determine the currently-viewed board panel.
    .value('getPanelName', (board, $stateParams) => {
        // If there's a panel specified in the state params, use that.
        var panelName = $stateParams.panel;
        // Otherwise find the board's first panel and use that.
        if (!panelName) {
            panelName = _.get(board, 'panels[0].name', undefined);
        }
        return panelName;
    })
    // Given a board and some state params, update the state params with info about the board.
    // Also add the board definition (if there is one) to the recently-viewed list.
    .factory('processBoard', [
        '$state',
        'getPanelName',
        'storageService',
        ($state, getPanelName, storageService) => {
            return function(board, $stateParams) {
                var panelName = getPanelName(board, $stateParams);
                $stateParams.panel = panelName;
                $stateParams.board = board.shortcode;
                $stateParams.title = board.title;
                $stateParams.id = null;
                _.set(board, 'state.currentPanel', panelName);
                if (board.shortcode == 'settings' && panelName == 'actions') {
                    _.set(board, '_boardLevelFiltersLabel', 'Plays');
                }

                // If the board has a definition ID, add this to the recent views for the board type.
                const definitionId = _.get(board, 'definition.id');
                if (definitionId) {
                    const boardType = _.get(board, 'definition.boardType');
                    const boardTypeStorage = storageService.getRaw(`boardDef-${boardType}`, 'local') || {};
                    const recent = _.get(boardTypeStorage, 'recent', []);
                    // If this board was in the recents list already, remove it.
                    _.remove(recent, { cardDefinitionId: definitionId });
                    // Create a new entry and put it at the top.
                    const definitionName = _.get(board, 'definition.name');
                    const newEntry = {
                        boardDefinitionName: definitionName,
                        boardType,
                        cardDefinitionId: definitionId,
                        href: $state.href('boards.board.panel', {
                            board: `board\$${definitionName}`,
                            panel: panelName
                        }),
                        title: _.get(board, 'definition.displayName'),
                        type: 'board'
                    };
                    recent.unshift(newEntry);

                    // Update local storage with the new data.
                    boardTypeStorage.recent = recent;
                    storageService.setRaw(`boardDef-${boardType}`, boardTypeStorage, 'local');
                }

                return board;
            };
        }
    ])
    .config([
        '$compileProvider',
        function($compileProvider) {
            // eslint-disable-next-line angular/window-service
            $compileProvider.debugInfoEnabled(!!window.__karma__);
        }
    ])
    .config([
        'httpRequestInterceptorCacheBusterProvider',
        function(httpRequestInterceptorCacheBusterProvider) {
            //add stuff to the array that you don't want having the cacheBuster suffix
            httpRequestInterceptorCacheBusterProvider.setMatchList(
                [
                    'template',
                    'token',
                    'toastr',
                    'modal',
                    'editcssselector',
                    'gallery_setup_prompts',
                    'images'
                ],
                false // the above is not a deny list, ie it's an allow list
            );
        }
    ])
    .config([
        '$httpProvider',
        function($httpProvider) {
            $httpProvider.interceptors.push('authInterceptor');
            $httpProvider.interceptors.push('monitoringInterceptor');
            $httpProvider.interceptors.push('indicatorInterceptor');
            $httpProvider.useApplyAsync(true);
        }
    ])
    .config([
        '$provide',
        function($provide) {
            $provide.decorator('$state', [
                '$delegate',
                function($delegate) {
                    var originalTransitionTo = $delegate.transitionTo;
                    $delegate.transitionTo = function(to, toParams, options) {
                        return originalTransitionTo(
                            to,
                            toParams,
                            angular.extend(
                                {
                                    reload: true,
                                    inherit: false,
                                    notify: true
                                },
                                options
                            )
                        );
                    };
                    return $delegate;
                }
            ]);
        }
    ])
    // Enable or disable debug logging to console
    // todo: disable by default unless a special cookie is set in the browser
    .config([
        '$logProvider',
        function($logProvider) {
            $logProvider.debugEnabled(true);
        }
    ])
    .config([
        '$locationProvider',
        function($locationProvider) {
            $locationProvider.html5Mode({
                enabled: false
            });
            //force bang urls not hashbang urls https://docs.angularjs.org/guide/$location
            $locationProvider.hashPrefix('');
        }
    ])
    .config([
        '$localStorageProvider',
        function($localStorageProvider) {
            $localStorageProvider.setKeyPrefix('insightSquared-');
        }
    ])
    .config([
        '$sessionStorageProvider',
        function($sessionStorageProvider) {
            $sessionStorageProvider.setKeyPrefix('insightSquared-');
        }
    ])
    .config([
        'ChartJsProvider',
        function(ChartJsProvider) {
            const chartColors = [
                'cyan-300',
                'blue-100',
                'cyan-100',
                'blue-200',
                'light-blue-300',
                'peach-300',
                'purple-200',
                'blue-300',
                'yellow-300'
            ].map(color => colors[color]);
            ChartJsProvider.setOptions({ global: { colors: chartColors } });
        }
    ])
    .config([
        '$stateProvider',
        '$urlRouterProvider',
        function($stateProvider, $urlRouterProvider) {
            $urlRouterProvider.otherwise('home/');

            // Common panel resolver for both regular routes and external routes
            const panelResolve = {
                domain: GetDomain,
                hasAuth: GetAuth,
                socket: GetSocket,
                user: GetUser,
                team: GetTeam,
                settings: [
                    'user',
                    'team',
                    'appSettingsService',
                    function(user, team, appSettingsService) {
                        return appSettingsService.initLD();
                    }
                ],
                query: GetQuery,
                facets: GetFacets,
                displayOptions: GetDisplayOptions,
                setGainsight: SetGainsight,
                filter: GetFilter,
                hierarchyMetadata: GetHierarchyMetadata,
                hierarchy: GetHierarchy,
                board: [
                    '_',
                    '$stateParams',
                    '$state',
                    '$rootScope',
                    'boardServiceNew',
                    'userServiceNew',
                    'teamServiceNew',
                    'storageService',
                    '$cookies',
                    'toastr',
                    'processBoard',
                    'hasAuth',
                    function(
                        _,
                        $stateParams,
                        $state,
                        $rootScope,
                        boardServiceNew,
                        userServiceNew,
                        teamServiceNew,
                        storageService,
                        $cookies,
                        toastr,
                        processBoard,
                        hasAuth
                    ) {
                        //Check to see if we can get the board definition board
                        let auditedBoard = auditBoardDef($stateParams);
                        if (auditedBoard) {
                            return auditedBoard.then(boardDef => {
                                //If we successfully get a stored boardDef use it.
                                if (boardDef) {
                                    return processBoard(boardDef, $stateParams);
                                }
                            });
                        } else if (
                            !$stateParams.overwriteReload &&
                            ($stateParams.board || $stateParams.externalId)
                        ) {
                            return getBoard($stateParams).then(board => {
                                return board ? processBoard(board, $stateParams) : undefined;
                            });
                        } else {
                            return;
                        }

                        //Tests to see if this board has a valid board Definition.
                        function auditBoardDef(params) {
                            let inBoardDef;

                            //if the supplied board definition is set, put it in the cookie for later use
                            //WIth multiple board type, we should have multiple board types in the cookie
                            const boardType = _.first(_.split(params.board, '$'));
                            if (params.boardDef) {
                                storageService.set(
                                    `boardDefRecent-${boardType}`,
                                    params.boardDef,
                                    null,
                                    'local'
                                );
                                inBoardDef = params.boardDef;
                            } else {
                                inBoardDef = storageService.get(`boardDefRecent-${boardType}`, 'local');
                                //TODO: Remove. In for backwards compatibility.
                                // If null, Check to see if there is a stored boardDef in the cookie.
                                if (!inBoardDef) {
                                    inBoardDef = $cookies.get(`boardDef-${boardType}`);
                                }
                            }

                            //If there isn't a valid board definition, then move on.
                            if (!inBoardDef) {
                                return undefined;
                            }

                            //Test to see if this is a board definition against the board passed in.
                            const isBoardDef = _.first(_.split(inBoardDef, '$')) === params.board;
                            if (isBoardDef) {
                                //Search for the board def
                                return getBoard($stateParams, inBoardDef).then(board => {
                                    //The cookie stored boardDef is no longer valid, remove it from the cookie store
                                    if (!board) {
                                        storageService.delete(`boardDefRecent-${boardType}`, 'local');
                                        // TODO: Remove. In for backwards compatibility
                                        $cookies.remove(`boardDef-${boardType}`);
                                    }
                                    return board;
                                });
                            } else {
                                return undefined;
                            }
                        }

                        function getBoard(params, storedShortcode) {
                            let shortcode = storedShortcode ? storedShortcode : params.board;
                            function handleError(err) {
                                //If the board cannot be found set it to 404 Page.
                                //If it is testing a stored board Definition return undefined and try another route
                                if (err.status === 404 && !storedShortcode && !$rootScope.isExternal) {
                                    //This was an ugly experience going to the 404 page. Send the default actions board.
                                    userServiceNew
                                        .getCachedOrFetch()
                                        .then(user => {
                                            if (
                                                params.isHome ||
                                                _.get(user, 'state.currentHomeBoard') === shortcode
                                            ) {
                                                toastr.error(
                                                    'User home board is not configured correctly, please check it and update it'
                                                );

                                                //if the user homepage is broke, final fail over to user profile to fix the home board
                                                $state.go('boards.board.panel', {
                                                    board: 'profile',
                                                    panel: undefined,
                                                    overwriteReload: false,
                                                    destinationTime: undefined,
                                                    query: undefined,
                                                    advanced: undefined,
                                                    filter: undefined,
                                                    targetId: undefined
                                                });
                                            } else {
                                                //Go to the users homepage
                                                $state.go('home.main');
                                            }
                                        })
                                        .catch(() => {
                                            $state.go('home.main');
                                        });
                                } else if (err.status === 401) {
                                    //If a session is timed out due to an idle session timeout the "hasAuth" check will not catch
                                    //the user being logged out because it doesn't know if a session has timed out until it makes
                                    //an authenticated request. If we navigate to a board and receive a 401 status code, it means
                                    //the session was timed out when trying to access the board so we should redirect the user to
                                    //the login page.
                                    //
                                    // TODO This might not be necessary after moving to a live API call for checking authentication.
                                    userServiceNew.logOut().then(function() {
                                        $state.go('core.login');
                                    });
                                } else if (err.status === 403) {
                                    userServiceNew
                                        .getCachedOrFetch()
                                        .then(user => {
                                            teamServiceNew.getCachedOrFetch().then(team => {
                                                $state.go('core.page403', {
                                                    user,
                                                    team,
                                                    errorCode: 403,
                                                    shortcode,
                                                    overwriteReload: true
                                                });
                                            });
                                        })
                                        .catch(() => {
                                            $state.go('core.page403');
                                        });
                                } else if (err.status === 404) {
                                    userServiceNew
                                        .getCachedOrFetch()
                                        .then(user => {
                                            teamServiceNew.getCachedOrFetch().then(team => {
                                                $state.go('core.page404', { user, team });
                                            });
                                        })
                                        .catch(() => {
                                            $state.go('core.page404');
                                        });
                                } else {
                                    //Other server errors put to 500 page
                                    if ($rootScope.isExternal) {
                                        //If embedded, lets not take people to random pages, but fail and give an error
                                        $state.go('external-error');
                                    } else {
                                        $state.go('core.page500');
                                    }
                                }
                            }

                            if (params.externalId) {
                                return boardServiceNew
                                    .getBoard({ externalId: params.externalId, facets: params.facets })
                                    .catch(handleError);
                            } else {
                                return boardServiceNew
                                    .getBoard({ shortcode: shortcode, facets: params.facets })
                                    .catch(handleError);
                            }
                        }
                    }
                ],
                panelCards: GetCards,
                panelGroup: [
                    '_',
                    '$stateParams',
                    'board',
                    function(_, $stateParams, board) {
                        if (!board) {
                            return undefined;
                        }

                        if (!$stateParams.panelGroup) {
                            $stateParams.panelGroup = 'main';
                        }

                        var panelGroup = _.find(board.panelGroups, function(value, key) {
                            return key == $stateParams.panelGroup;
                        });
                        _.set(board, 'state.currentGroup', $stateParams.panelGroup);
                        return panelGroup;
                    }
                ],
                panel: [
                    '_',
                    '$stateParams',
                    'board',
                    'panelGroup',
                    function(_, $stateParams, board, panelGroup) {
                        //If no board, no panels to return
                        if (!board) {
                            return undefined;
                        }

                        //get the panels for this panel group from the board
                        const panels = _.get(panelGroup, 'panels', []);

                        //if panels is empty return undefined
                        if (_.isEmpty(panels)) {
                            return undefined;
                        }

                        //If panel isn't set return the first panel in the panel group
                        if (!$stateParams.panel) {
                            return panels[0];
                        }

                        //return panel that matches the panel parameter
                        return _.find(panels, function(p) {
                            return p.name == $stateParams.panel;
                        });
                    }
                ]
            };

            $stateProvider
                .state('home', {
                    abstract: true,
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('home.main', {
                    url: '/home/',
                    data: {
                        pageTitle: 'Home'
                    },
                    // redirect to new state
                    controller: [
                        '$state',
                        '$stateParams',
                        'hasAuth',
                        'team',
                        'user',
                        function($state, $stateParams, hasAuth, team, user) {
                            // set home board
                            let userSettingHomeBoard = _.get(user, 'state.currentHomeBoard');
                            let teamSettingHomeBoard = _.get(team, 'currentHomeBoard');
                            let homeBoard = _.get(user, 'navigation.primary[0].shortcode', 'profile');
                            if (userSettingHomeBoard) {
                                homeBoard = userSettingHomeBoard;
                            } else if (teamSettingHomeBoard) {
                                homeBoard = teamSettingHomeBoard;
                            }
                            $state.transitionTo('boards.board.panel', {
                                board: homeBoard,
                                panel: undefined,
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined,
                                targetId: undefined,
                                hierarchy: undefined,
                                isHome: true
                            });
                        }
                    ],
                    resolve: {
                        domain: GetDomain,
                        hasAuth: GetAuth,
                        user: GetUser,
                        team: GetTeam
                    }
                })
                .state('boards', {
                    abstract: true,
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('boards.settings', {
                    url: '/boards/{board}/settings/{panel}?success&referrer&error',
                    controller: 'PanelController',
                    template: require('views/tmpl/pages/panel.html'),
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Board Settings'
                    },
                    resolve: {
                        domain: GetDomain,
                        hasAuth: GetAuth,
                        socket: GetSocket,
                        user: GetUser,
                        team: GetTeam,
                        settings: [
                            'user',
                            'team',
                            'appSettingsService',
                            function(user, team, appSettingsService) {
                                return appSettingsService.initLD();
                            }
                        ],
                        query: GetQuery,
                        facets: GetFacets,
                        displayOptions: GetDisplayOptions,
                        setGainsight: SetGainsight,
                        hierarchyMetadata: GetHierarchyMetadataSkip,
                        hierarchy: GetHierarchy,
                        board: [
                            '_',
                            '$stateParams',
                            'boardServiceNew',
                            'hasAuth',
                            function(_, $stateParams, boardServiceNew, hasAuth) {
                                if ($stateParams.overwriteReload) {
                                    return;
                                }

                                return boardServiceNew
                                    .getBoard({
                                        shortcode: $stateParams.board,
                                        id: $stateParams.id
                                    })
                                    .then(processBoard);

                                function processBoard(board) {
                                    board.panels = board.panelGroups.settings.panels;
                                    var panelName = getPanelName(board);
                                    $stateParams.panel = panelName;
                                    $stateParams.board = board.shortcode;
                                    $stateParams.title = board.title;
                                    _.set(board, 'state.currentPanel', panelName);
                                    _.set(board, 'state.currentGroup', 'settings');
                                    return board;
                                }

                                function getPanelName(board) {
                                    var panelName = $stateParams.panel;
                                    if (!panelName) {
                                        panelName = _.get(board, 'panels[0].name', undefined);
                                    }

                                    return panelName;
                                }
                            }
                        ],
                        filter: GetFilter,
                        panelCards: GetCards
                    },
                    params: {
                        panel: {
                            value: null,
                            squash: true
                        },
                        id: {
                            value: null,
                            squash: true
                        },
                        overwriteReload: {
                            value: false,
                            squash: true
                        },
                        success: {
                            value: null,
                            squash: true
                        },
                        referrer: {
                            value: null,
                            squash: false
                        },
                        error: {
                            value: null,
                            squash: false
                        }
                    }
                })
                .state('boards.ml', {
                    url: '/boards/{board}/ml/{panel}?success&referrer&error',
                    controller: 'PanelController',
                    template: require('views/tmpl/pages/panel.html'),
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Machine Learning Inspection'
                    },
                    resolve: {
                        domain: GetDomain,
                        hasAuth: GetAuth,
                        socket: GetSocket,
                        user: GetUser,
                        team: GetTeam,
                        settings: [
                            'user',
                            'team',
                            'appSettingsService',
                            function(user, team, appSettingsService) {
                                return appSettingsService.initLD();
                            }
                        ],
                        query: GetQuery,
                        facets: GetFacets,
                        displayOptions: GetDisplayOptions,
                        setGainsight: SetGainsight,
                        hierarchyMetadata: GetHierarchyMetadataSkip,
                        hierarchy: GetHierarchy,
                        board: [
                            '_',
                            '$stateParams',
                            'boardServiceNew',
                            'hasAuth',
                            function(_, $stateParams, boardServiceNew, hasAuth) {
                                if ($stateParams.overwriteReload) {
                                    return;
                                }

                                return boardServiceNew
                                    .getBoard({
                                        shortcode: $stateParams.board,
                                        id: $stateParams.id
                                    })
                                    .then(processBoard);

                                function processBoard(board) {
                                    board.panels = board.panelGroups.ml.panels;
                                    var panelName = getPanelName(board);
                                    $stateParams.panel = panelName;
                                    $stateParams.board = board.shortcode;
                                    $stateParams.title = board.title;
                                    _.set(board, 'state.currentPanel', panelName);
                                    _.set(board, 'state.currentGroup', 'ml');
                                    return board;
                                }

                                function getPanelName(board) {
                                    var panelName = $stateParams.panel;
                                    if (!panelName) {
                                        panelName = _.get(board, 'panels[0].name', undefined);
                                    }

                                    return panelName;
                                }
                            }
                        ],
                        filter: GetFilter,
                        panelCards: GetCards
                    },
                    params: {
                        panel: {
                            value: null,
                            squash: true
                        },
                        id: {
                            value: null,
                            squash: true
                        },
                        overwriteReload: {
                            value: false,
                            squash: true
                        },
                        success: {
                            value: null,
                            squash: true
                        },
                        referrer: {
                            value: null,
                            squash: false
                        },
                        error: {
                            value: null,
                            squash: false
                        }
                    }
                })
                .state('boards.board', {
                    abstract: true,
                    controller: 'BoardController',
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('boards.board.panel', {
                    url:
                        '/boards/{board}/{panel}?query&destinationTime&advanced&facets&filter&targetId&hierarchy&dashFacets&rightDrawerData&leftDrawerData&overrides',
                    controller: 'PanelController',
                    template: require('views/tmpl/pages/panel.html'),
                    reloadOnSearch: false,
                    resolve: panelResolve,
                    params: {
                        panel: {
                            value: null,
                            squash: true
                        },
                        id: {
                            value: null,
                            squash: true
                        },
                        overwriteReload: {
                            value: false,
                            squash: true
                        },
                        boardCache: {
                            value: null,
                            squash: true
                        },
                        cardCache: {
                            value: null,
                            squash: true
                        },
                        boardDef: {
                            value: null,
                            squash: true
                        },
                        isHome: {
                            value: false
                        }
                    }
                })
                .state('apps', {
                    abstract: true,
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('apps.all', {
                    url: '/apps/{panel}',
                    controller: 'AppsController',
                    template: require('views/tmpl/setup/app.html'),
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Applications'
                    },
                    resolve: {
                        domain: GetDomain,
                        hasAuth: GetAuth,
                        socket: GetSocket,
                        user: GetUser,
                        team: GetTeam,
                        displayOptions: GetDisplayOptions,
                        setGainsight: SetGainsight,
                        integrations: [
                            'integrationService',
                            function(integrationService) {
                                return integrationService.searchIntegrations();
                            }
                        ],
                        apps: [
                            'appServiceNew',
                            function(appServiceNew) {
                                return appServiceNew.getApps();
                            }
                        ],
                        board: [
                            // At some point, we should figure out how to use the default panel board processor
                            '$stateParams',
                            'boardServiceNew',
                            'hasAuth',
                            function($stateParams, boardServiceNew, hasAuth) {
                                function processBoard(board) {
                                    let panelName = getPanelName(board);
                                    $stateParams.panel = panelName;
                                    $stateParams.board = board.shortcode;
                                    $stateParams.title = board.title;
                                    $stateParams.id = null;
                                    _.set(board, 'state.currentPanel', panelName);
                                    return board;
                                }

                                function getPanelName(board) {
                                    var panelName = $stateParams.panel;
                                    if (!panelName) {
                                        panelName = _.get(board, 'panels[0].name', undefined);
                                    }
                                    return panelName;
                                }

                                return boardServiceNew.getBoard({ shortcode: 'apps' }).then(processBoard);
                            }
                        ]
                    },
                    params: {
                        panel: {
                            value: null,
                            squash: true
                        },
                        board: {
                            value: null,
                            squash: true
                        }
                    }
                })
                .state('integrations', {
                    abstract: true,
                    template: '<div ui-view></div>'
                })
                .state('integrations.callback', {
                    url: '/integrations/callback?success&error',
                    controller: 'IntegrationCallbackController',
                    template: require('views/tmpl/setup/integrations/callbackHandler.html')
                })
                .state('team', {
                    abstract: true,
                    template: '<ui-view class="service-content bg-white" ng-cloak></ui-view>'
                })
                .state('team.setup', {
                    url: '/team/setup',
                    // redirect to new state
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            $state.transitionTo('boards.board.panel', {
                                board: 'team-profile',
                                panel: 'setup',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined
                            });
                        }
                    ]
                })
                .state('team.users', {
                    url: '/team/users',
                    // redirect to new state
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            $state.transitionTo('boards.board.panel', {
                                board: 'users',
                                panel: 'main',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined
                            });
                        }
                    ]
                })
                .state('team.jobs', {
                    url: '/team/jobs',
                    // redirect to new state
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            $state.transitionTo('boards.board.panel', {
                                board: 'jobs',
                                panel: 'main',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined
                            });
                        }
                    ]
                })
                .state('team.groups', {
                    url: '/team/groups',
                    // redirect to new state
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            $state.transitionTo('boards.board.panel', {
                                board: 'settings',
                                panel: 'groups',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined
                            });
                        }
                    ]
                })
                .state('analytics', {
                    abstract: true,
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('settings', {
                    url: '/settings/{panel}?&targetId',
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            let panel = _.get($stateParams, 'panel');
                            let board = _.get(oldSettingPanelMapping, panel, 'action-definition');
                            $state.transitionTo('boards.board.panel', {
                                board: board,
                                panel: 'main',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined,
                                targetId: _.get($stateParams, 'targetId')
                            });
                        }
                    ]
                })
                .state('admin', {
                    url: '/admin/{panel}?&targetId',
                    controller: [
                        '$state',
                        '$stateParams',
                        function($state, $stateParams) {
                            let panel = _.get($stateParams, 'panel');
                            let board = _.get(oldSettingPanelMapping, panel, 'action-definition');
                            $state.transitionTo('boards.board.panel', {
                                board: board,
                                panel: 'main',
                                overwriteReload: false,
                                destinationTime: undefined,
                                query: undefined,
                                advanced: undefined,
                                filter: undefined,
                                targetId: _.get($stateParams, 'targetId')
                            });
                        }
                    ]
                })
                .state('external', {
                    url:
                        '/external?board&boardDef&externalId&panelGroup&panel&card&query&destinationTime&advanced&facets&filter&targetId&singleView&hierarchy&dashFacets&overrides',
                    controller: 'ExternalController',
                    isExternal: true,
                    template: require('views/tmpl/pages/external.html'),
                    resolve: panelResolve,
                    params: {
                        layout: {
                            value: undefined,
                            squash: true
                        },
                        singleView: {
                            value: undefined,
                            squash: true
                        },
                        overwriteReload: {
                            value: false,
                            squash: true
                        }
                    }
                })
                .state('external-error', {
                    url: '/external-error/{id}',
                    controller: 'ExternalErrorController',
                    isExternal: true,
                    template: require('views/tmpl/pages/external-error.html')
                })
                //app core pages (errors, login, signup)
                .state('core', {
                    abstract: true,
                    url: '/core',
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                //login
                .state('core.login', {
                    url: '/login',
                    controller: 'LoginCtrl',
                    template: require('views/tmpl/pages/login.html'),
                    resolve: {
                        domain: GetDomain,
                        hasAuth: GetAuth
                    },
                    params: {
                        redirectState: 'home.main',
                        redirectParams: {}
                    }
                })
                //logout
                .state('core.logout', {
                    url: '/logout',
                    controller: [
                        '$state',
                        'userServiceNew',
                        '$window',
                        '$rootScope',
                        function($state, userServiceNew, $window, $rootScope) {
                            userServiceNew.logOut().then(() => {
                                $rootScope.$emit('logout');
                                $state.go('core.logged-out');
                            });
                        }
                    ]
                })
                //post-logout, so we don't trigger SSO login again
                .state('core.logged-out', {
                    url: '/logged-out',
                    template: require('views/tmpl/pages/logged-out.html')
                })
                //signup
                .state('core.signup', {
                    url: '/signup?&teamCreateSecret',
                    controller: 'SignupCtrl',
                    template: require('views/tmpl/pages/signup.html'),
                    params: {
                        teamCreateSecret: {
                            value: undefined,
                            squash: true
                        }
                    }
                })
                //forgot password
                .state('core.forgotpass', {
                    url: '/forgotpass',
                    controller: 'ForgotPasswordCtrl',
                    template: require('views/tmpl/pages/forgotpass.html')
                })
                //set password
                .state('core.resetpassword', {
                    url: '/resetpassword/{token}',
                    controller: 'SetPasswordCtrl',
                    template: require('views/tmpl/pages/setpass.html')
                })
                .state('core.page403', {
                    url: '/page403',
                    controller: 'ClientErrorResponse',
                    template: require('views/tmpl/pages/clientErrorResponse.html'),
                    resolve: {
                        domain: [function() {}],
                        hasAuth: GetAuth,
                        user: GetUser,
                        team: GetTeam
                    },
                    params: {
                        errorCode: {
                            value: 403,
                            squash: true
                        },
                        shortcode: {
                            value: null,
                            squash: true
                        }
                    }
                })
                //page 404
                .state('core.page404', {
                    url: '/page404',
                    controller: 'ClientErrorResponse',
                    template: require('views/tmpl/pages/clientErrorResponse.html'),
                    resolve: {
                        domain: [function() {}],
                        hasAuth: GetAuth,
                        user: GetUser,
                        team: GetTeam
                    },
                    params: {
                        errorCode: {
                            value: 404,
                            squash: true
                        }
                    }
                })
                //page 500
                .state('core.page500', {
                    url: '/page500',
                    controller: 'Page500Ctrl',
                    template: require('views/tmpl/pages/page500.html')
                })
                // login error state.
                .state('core.loginError', {
                    url: '/loginError?reason',
                    controller: 'LoginErrorController',
                    template: require('views/tmpl/pages/login-error.html')
                })
                //app mobile routes
                .state('mobile', {
                    abstract: true,
                    url: '/mobile',
                    template: require('views/tmpl/pages/panelContainer.html')
                })
                .state('mobile.home', {
                    url: '/home',
                    controller: 'MobileHomeCtrl',
                    template: require('views/tmpl/pages/mobilehome.html')
                })
                .state('mobile.view', {
                    url: '/view?&type&board',
                    controller: 'MobileViewController',
                    template: require('views/tmpl/pages/mobileview.html'),
                    resolve: {
                        domain: [function() {}],
                        hasAuth: GetAuth,
                        socket: GetSocket,
                        user: GetUser,
                        displayOptions: GetDisplayOptions,
                        board: [
                            '_',
                            'boardServiceNew',
                            '$stateParams',
                            function(_, boardServiceNew, $stateParams) {
                                return boardServiceNew.getBoard({
                                    shortcode: _.get($stateParams, 'boardType')
                                        ? _.get($stateParams, 'boardType')
                                        : 'actions'
                                });
                            }
                        ],
                        type: [
                            '_',
                            '$stateParams',
                            function(_, $stateParams) {
                                return _.get($stateParams, 'type') ? _.get($stateParams, 'type') : 'instant';
                            }
                        ]
                    }
                })
                .state('mobile.instant', {
                    url: '/instant?&name&id&globalId&contentId&boardId',
                    template: '<mobile-instant></mobile-instant>',
                    resolve: {
                        domain: [function() {}],
                        hasAuth: GetAuth,
                        user: GetUser
                    },
                    params: {
                        name: {
                            value: undefined,
                            squash: true
                        },
                        id: {
                            value: undefined,
                            squash: true
                        },
                        globalId: {
                            value: undefined,
                            squash: true
                        },
                        contentId: {
                            value: undefined,
                            squash: true
                        },
                        boardId: {
                            value: undefined,
                            squash: true
                        }
                    }
                });

            // This resolver is responsible for ensuring the browser is on the appropriate domain for the
            // authenticated user.
            GetDomain.$inject = ['_', '$rootScope', '$cookies', '$window', '$location', '$q'];
            function GetDomain(_, $rootScope, $cookies, $window, $location, $q) {
                return $q(function(resolve, reject) {
                    // If we're being served on intelligence.mediafly.com / intelligence.mediafly.test
                    // DO NOT REDIRECT!
                    if ($rootScope.isMediaflyCom) {
                        return resolve();
                    }

                    const href = $window.location.href;
                    const domain = $cookies.get('serviceDomain');
                    if (domain && !_.includes(href, domain)) {
                        const redirectUrl = `${$location.protocol()}://${domain}${$window.location.pathname}${
                            $window.location.hash
                        }`;
                        $window.location.href = redirectUrl;

                        // If we hit this path, then do NOT resolve the Promise. This prevents further
                        // API calls after we've decided to redirect.
                    } else {
                        resolve(domain);
                    }
                });
            }

            // Depend on `domain` here so any redirection happens before we attempt to load data.
            GetAuth.$inject = [
                'userServiceNew',
                '$state',
                '$timeout',
                '$rootScope',
                'storageService',
                'domain'
            ];
            function GetAuth(userServiceNew, $state, $timeout, $rootScope, storageService) {
                const redirectToLogin = () => {
                    storageService.flush();

                    if (
                        $rootScope.toState.name != 'core.login' &&
                        $rootScope.toState.name != 'core.resetpassword'
                    ) {
                        const stateParams = {
                            redirectState: $rootScope.toState.name,
                            redirectParams: $rootScope.toParams
                        };

                        $rootScope.$emit('logout');
                        $state.go('core.login', stateParams);

                        // to abort further resolvers
                        throw new Error('User not authenticated');
                    }
                };

                return userServiceNew
                    .isAuthenticated()
                    .then(hasAuth => {
                        if (hasAuth) {
                            return true;
                        }

                        redirectToLogin();
                    })
                    .catch(err => {
                        if (err.status === 403) {
                            $state.go('core.loginError', {
                                reason: err.data.error || 'You do not have permission to access this page.'
                            });
                            throw err;
                        }

                        return redirectToLogin();
                    });
            }

            // Depend on `hasAuth` here so any redirection happens before we attempt to load data.
            GetUser.$inject = ['$stateParams', 'userServiceNew', 'hasAuth', '$rootScope'];
            function GetUser($stateParams, userServiceNew, hasAuth, $rootScope) {
                if ($stateParams.overwriteReload) {
                    return;
                }

                return userServiceNew.getCachedOrFetch().then(user => {
                    $rootScope.user = user.toJSON ? user.toJSON() : user;
                    return user;
                });
            }

            // Depend on `hasAuth` here so any redirection happens before we attempt to load data.
            GetTeam.$inject = ['$stateParams', 'teamServiceNew', 'hasAuth', '$rootScope'];
            function GetTeam($stateParams, teamServiceNew, hasAuth, $rootScope) {
                if ($stateParams.overwriteReload) {
                    return;
                }

                // Get the team and immediately set the timezone in the root scope so it
                // can be utilized by any code that needs it.
                return teamServiceNew.getCachedOrFetch().then(team => {
                    $rootScope.team = team.toJSON ? team.toJSON() : team;
                    $rootScope.timezone = team.timezone;
                    return team;
                });
            }

            // Depend on `hasAuth` here so any redirection happens before we attempt to load data.
            GetDisplayOptions.$inject = ['$rootScope', 'contentTypeService', 'hasAuth', 'socket'];
            function GetDisplayOptions($rootScope, contentTypeService, hasAuth, socket) {
                const WEBSOCKET_EVENT = 'content_display_options_updated';
                // If we haven't already, bind a socket event listener for reloading content display options.
                if (!$rootScope.globalSocketListeners[WEBSOCKET_EVENT]) {
                    $rootScope.globalSocketListeners[WEBSOCKET_EVENT] = () => {
                        // Prevents double-loading if we are the ones who triggered the websocket event.
                        $rootScope.forceDisplayOptionsLoad = false;
                        contentTypeService.getContentDisplayOptions({ useCache: false });
                    };
                    // Bind the event handler.
                    socket.on(WEBSOCKET_EVENT, $rootScope.globalSocketListeners[WEBSOCKET_EVENT]);
                }

                // Attempt to use the cached display options unless we are explicitly told not to.
                const useCache = !_.get($rootScope, 'forceDisplayOptionsLoad', false);

                // Only explicitly load the options once per full page load.
                $rootScope.forceDisplayOptionsLoad = false;

                // Load the options.
                return contentTypeService.getContentDisplayOptions({ useCache });
            }

            //Setup Gainsight PX
            SetGainsight.$inject = ['$rootScope', 'team', 'user', '$location', '$window'];
            function SetGainsight($rootScope, team, user, $location, $window) {
                if ($window && is2DisableTrackingPattern.test($window.name)) {
                    return;
                }
                //Log into GAINSIGHT PX
                const domain = $location.host();
                if (
                    !$rootScope.gainsightInitialized &&
                    !_.includes(domain, $rootScope.productDevelopmentHostname) &&
                    user &&
                    team
                ) {
                    aptrinsic(
                        'identify',
                        {
                            // User Fields
                            id: _.get(user, 'id'),
                            email: _.get(user, 'email'),
                            teamId: _.get(user, 'state.currentTeam'),
                            isAdmin: _.get(user, 'isOwner')
                        },
                        {
                            // Team Fields
                            id: _.get(team, 'id'),
                            name: _.get(team, 'shortcode')
                        }
                    );
                    //Only log in once
                    $rootScope.gainsightInitialized = true;
                }
            }

            GetCards.$inject = [
                '$stateParams',
                '$state',
                '$rootScope',
                'cardsUtilService',
                'definitionService',
                'storageService',
                'board',
                'hierarchy',
                'query',
                'facets',
                '$cookies',
                'toastr',
                'moment'
            ];
            function GetCards(
                $stateParams,
                $state,
                $rootScope,
                cardsUtilService,
                definitionService,
                storageService,
                board,
                hierarchy,
                query,
                facets,
                $cookies,
                $moment
            ) {
                // If any cards were passed in the state as `cardCache` return them rather than
                // making an API call. This is used when we want to transition to a new state (hence
                // triggering the resolvers) but we already have cards loaded.
                if ($stateParams.cardCache) {
                    var cachedCards = _.cloneDeep($stateParams.cardCache);
                    $stateParams.cardCache = null;
                    return cachedCards;
                }

                // If we disable reload, then we're transition to a new state but don't want to
                // actually reload data. Do nothing.
                if ($stateParams.overwriteReload || !board) {
                    return;
                }

                var panelName = _.get(board, 'state.currentPanel');
                var cardName = _.get($stateParams, 'card');
                var singleView = _.get($stateParams, 'singleView');

                //By default use the boards first panel
                if (!panelName) {
                    panelName = _.get(board, 'panels[0].name', undefined);
                }

                // If we're in "single view" mode (usually from an external route)
                // and we have a particular card we're trying to load, just request
                // that card.
                if (singleView && cardName) {
                    //Convert to array in case we have multiple cards like for the tabbed view
                    cardName = _.split(cardName, ',');
                    return cardsUtilService
                        .getCards({
                            boardId: _.get(board, 'id'),
                            definitionName: _.get(board, 'definitionName'),
                            panelName: panelName,
                            panelGroup: _.get(board, 'state.currentGroup') || '',
                            cardName,
                            query,
                            facets,
                            ignoreLazy: true,
                            hierarchy
                        })
                        .catch(() => {
                            $state.go('external-error');
                        })
                        .finally(() => {
                            //turn off the loading overlay
                            // shouldn't this be done in the state change success handler?
                            $rootScope.routeIsChanging = false;
                        });
                }

                // If the card has a board layout, use the generated card list rather than
                // fetch the entire panel. This is mainly the Dashboards board, where we have
                // the layout data saved into the board definition.
                //
                // Skip external views for now.
                const hasLayout = _.get(board, 'definition.propertySchema.layout');
                if (hasLayout && !$rootScope.isExternal) {
                    return cardsUtilService.getLayoutCards(board, panelName);
                }

                // the external view controller will handle loading the card in this case
                if ($stateParams.board === 'insights$_report' && $rootScope.isExternal && $stateParams.card) {
                    return null;
                }

                return cardsUtilService
                    .getPanel(
                        _.get(board, 'id'),
                        _.get(board, 'definitionName'),
                        panelName,
                        _.get(board, 'state.currentGroup') || '',
                        query,
                        facets,
                        null,
                        null,
                        true,
                        null,
                        null,
                        hierarchy
                    )
                    .then(cards => {
                        //Remove any fail over attempts
                        $cookies.remove('failOver');
                        return cards;
                    })
                    .catch(function(err) {
                        //At this point something bad is happening. It may be the filter, it may be the board
                        //definition, could be a bad facet. In this case, we'll flush what we have and fall over
                        //to the default state for this panel. We don't want this to infinite loop so add a flag
                        //that we are retrying and bail out after a known good state failure.

                        if ($rootScope.isExternal) {
                            //If embedded, lets not take people to random pages, but fail and give an error
                            $state.go('external-error');
                        } else if (!$cookies.get('failOver')) {
                            $log.error('Failed to load the page. Loading the default parameters', err);
                            //Clear out the Board Definition
                            storageService.delete(
                                `boardDefRecent-${_.first(_.split($stateParams.board, '$'))}`,
                                'local'
                            );
                            // TODO: remove. In for backwards compatibility
                            $cookies.remove(`boardDef-${_.first(_.split($stateParams.board, '$'))}`);
                            //Clear out any filters
                            definitionService.deleteStoredFilter(_.first(_.split($stateParams.board, '$')));
                            //Clear out any facets
                            storageService.delete(`${_.get(board, 'id')}-facets`, 'local');
                            //Set a retry flag to prevent infinite loops
                            $cookies.put('failOver', true, {
                                expires: $moment()
                                    .add(10, 'seconds')
                                    .toDate()
                            });

                            //Reload the base version of this panel
                            $state.transitionTo(
                                $state.current.name,
                                {
                                    panel: panelName,
                                    board: _.get(board, 'id'),
                                    overwriteReload: true
                                },
                                {
                                    location: true,
                                    notify: true,
                                    reload: true
                                }
                            );
                        } else if (_.get($state, 'current.name') !== 'home.main') {
                            //If the attempted page is not already home.main
                            $state.go('home.main');
                        } else {
                            //Home is not working, send a 500 page
                            $state.go('core.page500');
                        }
                    })
                    .finally(() => {
                        //turn off the loading overlay
                        // shouldn't this be done in the state change success handler?
                        $rootScope.routeIsChanging = false;
                    });
            }

            GetQuery.$inject = ['$stateParams', 'board'];
            function GetQuery($stateParams, board) {
                if ($stateParams.overwriteReload) {
                    return;
                }

                var query = {
                    str: $stateParams.query ? $stateParams.query : '',
                    destinationTime: $stateParams.destinationTime ? $stateParams.destinationTime : '',
                    advancedQuery: $stateParams.advanced ? $stateParams.advanced == 'true' : ''
                };
                return query;
            }

            GetFacets.$inject = ['_', '$stateParams', 'storageService', 'board', 'filter'];
            function GetFacets(_, $stateParams, storageService, board, filter) {
                if ($stateParams.overwriteReload) {
                    return;
                }

                var facets = $stateParams.facets;
                let defaultFacets;

                if (filter) {
                    const sortObj = _.get(filter, 'propertySchema.filter._display.sort');
                    if (sortObj) {
                        defaultFacets = {
                            _default: {
                                sort: sortObj
                            }
                        };
                    }
                }

                if (_.isObject(facets)) {
                    facets = _.merge(facets, defaultFacets);
                    return facets;
                }

                if (facets) {
                    try {
                        facets = JSON.parse(facets);
                        facets = _.merge(facets, defaultFacets);
                        return facets;
                    } catch (e) {}
                }

                if (board) {
                    var storedFacets = storageService.get(`${board.id}-facets`, 'local');
                    if (storedFacets) {
                        try {
                            facets = JSON.parse(storedFacets);
                            facets = _.merge(facets, defaultFacets);
                            return facets;
                        } catch (e) {}
                    }
                }

                return defaultFacets || {};
            }

            GetFilter.$inject = [
                '_',
                '$stateParams',
                '$rootScope',
                'definitionService',
                'board',
                '$log',
                '$q'
            ];
            function GetFilter(_, $stateParams, $rootScope, definitionService, board, $log, $q) {
                //This will test the current selected filter to see if it's a valid filter from the backend
                //If not, delete it from the local storage and from the currentFilter.
                const auditedFilter = function(filterId) {
                    return definitionService
                        .getDefinitionById(filterId)
                        .then(definition => {
                            // Only expect "filter" definitions to be stored in local storage.
                            if (definition && definition.definitionType !== 'filter') {
                                throw new Error(
                                    `auditedFilter expected a definition of type "filter"; got ${definition.definitionType}`
                                );
                            }
                            return $q.resolve(definition);
                        })
                        .catch(e => {
                            //If the incoming filter fails to fetch from the backend. Delete the filter from
                            //the local store, and unset the currentFilter.
                            $log.error('Could not find remote filter', { error: e, filter: filterId });
                            definitionService.deleteStoredFilter(board || $stateParams.board);
                            return {};
                        });
                };

                //This will parse the filter format. It might be a filter fragment, JSON string or a filter object
                const parseAndSetFilter = function(filter, editing) {
                    let resolvedFilter = filter;

                    function setCurrentFilter(resolvedFilter) {
                        $rootScope.currentFilter = !_.isEmpty(resolvedFilter) ? resolvedFilter : undefined;
                        return resolvedFilter;
                    }

                    //The filter may be in a string format, either as a underscore filter fragment, or a JSON String.
                    if (_.isString(filter)) {
                        // If the filter string starts with an underscore, we'll interpret it as a filter definition ID.
                        if (filter[0] === '_') {
                            // Get the definition ID.
                            filter = filter.substring(1);
                            // Test the filter fragment to see if it is a valid filter.
                            return auditedFilter(filter).then(setCurrentFilter);
                        } else {
                            // Otherwise attempt to parse the filter string as JSON.
                            try {
                                resolvedFilter = JSON.parse(filter);
                                // Test the filter to see if it is a valid filter.
                                return auditedFilter(resolvedFilter.id).then(setCurrentFilter);
                            } catch (e) {
                                //If the filter fails to parse, unset the currentFilter.
                                $log.error('Error attempting to parse filter', { error: e, filter: filter });
                                // resolvedFilter = {};
                                return setCurrentFilter({});
                            }
                        }
                    } else {
                        //If the filter is being edited in flight use the passed filter
                        if (editing) {
                            return setCurrentFilter(resolvedFilter);
                        }
                        // Test the filter to see if it is a valid filter.
                        return auditedFilter(filter.id).then(setCurrentFilter);
                    }
                };

                if ($stateParams.overwriteReload) {
                    return;
                }

                //See if the current filter is stored in local storage
                const storedFilter = definitionService.getStoredFilter(board || $stateParams.board);

                //check the state of the stored filter to see if it's being edited, as to not lose changes between navigation changes
                const isEditing = $rootScope.isEditingFilter || (storedFilter && !storedFilter.id);

                if ($stateParams.filter && !isEditing) {
                    //If the filter is set on the URL, it takes top priority as a possible direct link.
                    return parseAndSetFilter($stateParams.filter);
                } else if (storedFilter) {
                    //If there isn't a filter on the URL but there is a stored filter use it.
                    return parseAndSetFilter(storedFilter, isEditing);
                } else {
                    //If there is no parameter filter and no stored filter, return and empty filter
                    $rootScope.currentFilter = undefined;
                    return {};
                }
            }

            // Some places need hierarchy, but not hierarchyMetadata, but hierarchy always needs hierarchyMetadata
            // so use this function instead in those places so we just return undefined if we don't need the metadata
            GetHierarchyMetadataSkip.$inject = [];
            function GetHierarchyMetadataSkip() {
                return undefined;
            }

            GetHierarchyMetadata.$inject = [
                '_',
                '$rootScope',
                '$log',
                '$stateParams',
                'hierarchyService',
                'board'
            ];
            function GetHierarchyMetadata(_, $rootScope, $log, $stateParams, hierarchyService, board) {
                // don't load hierarchy metadata if hierarchy is disabled/unsupported or if other hierarchy data
                // (ex. saved board setting, node selection) takes priority
                if (
                    $stateParams.overwriteReload || // are we skipping reloading data (will never be true during a state transition)
                    _.get(board, 'definition.hierarchy.enabled') === false || // is hierarchy disabled in board defaults
                    (_.get($rootScope, 'board.properties.availableHierarchies') === false && // are there available hierarchies
                        _.get($rootScope, 'board.properties.supportsHierarchy') === false) // is hierarchy supported
                ) {
                    return undefined;
                }

                return hierarchyService
                    .getHierarchyMetadata()
                    .then(metadata => {
                        const defaultSelection = {
                            definitionId: _.get(metadata, 'definitionId'),
                            contentUserId: _.get(metadata, 'contentUserId'),
                            rootId: _.get(metadata, 'defaultNodeId')
                        };
                        return defaultSelection;
                    })
                    .catch(() => {
                        $log.error('Error retrieving hierarchy metadata');
                        return undefined;
                    });
            }

            GetHierarchy.$inject = ['_', '$stateParams', 'hierarchyMetadata', 'board'];
            function GetHierarchy(_, $stateParams, hierarchyMetadata, board) {
                let defaultHierarchySelection = hierarchyMetadata;
                let hierarchy;

                // if board doesn't support hierarchy, it shouldn't have hierarchy
                if (board && !_.get(board, 'properties.supportsHierarchy')) {
                    hierarchy = undefined;
                }
                // deep linked hierarchy should always be respected
                else if ($stateParams.hierarchy) {
                    hierarchy = $stateParams.hierarchy;
                }
                // board object is only included on initial load and in that case we should prioritize board-level defaults
                else if (
                    _.get(board, 'properties.availableHierarchies') ||
                    _.get(board, 'properties.supportsHierarchy')
                ) {
                    if (_.get(board, 'definition.hierarchy.rootId')) {
                        // if there's complete saved hierarchy info, use it
                        const boardHierarchy = _.get(board, 'definition.hierarchy');
                        /*eslint no-unused-vars: ["warn", { "ignoreRestSiblings": true }]*/
                        const { enabled, ...hierarchyInfo } = boardHierarchy;
                        hierarchy = hierarchyInfo;
                    } else if (_.get(board, 'definition.hierarchy.definitionId')) {
                        // if there's a definitionId but no node, return a cleared hierarchy
                        hierarchy = undefined;
                    } else {
                        hierarchy = defaultHierarchySelection;
                    }
                }
                // node selected from HierarchySelector after initial load should be prioritized over saved board definition
                else {
                    hierarchy = defaultHierarchySelection;
                }

                if (!hierarchy) {
                    return hierarchy;
                }
                if (_.isObject(hierarchy)) {
                    return hierarchy;
                }
                try {
                    hierarchy = JSON.parse($stateParams.hierarchy);
                } catch (e) {}

                return hierarchy;
            }

            CheckOwner.$inject = ['$state', '$timeout', 'user', '$stateParams'];
            function CheckOwner($state, $timeout, user, $stateParams) {
                if ($stateParams.overwriteReload || user.isOwner) {
                    return;
                }

                $timeout(function() {
                    $state.go('home.main');
                });
            }

            GetSocket.$inject = ['$rootScope', '$window', 'hasAuth'];
            function GetSocket($rootScope, $window, hasAuth) {
                if ($rootScope.socket) {
                    return $rootScope.socket;
                }

                // Connect to the websocket server.
                const socketOptions = {
                    path: '/webclient-sockets',
                    transports: ['websocket'],
                    reconnectionAttempts: 10
                };

                if ($rootScope.mcode) {
                    _.set(socketOptions, 'query.mcode', $rootScope.mcode);
                }

                if ($window.location.search.match(/[?&]nowebsocket($|&|\/)/)) {
                    socketOptions.autoConnect = false;
                }

                const socket = io('//', socketOptions);
                $rootScope.socket = socket;

                return socket;
            }
        }
    ]);
